import { default as indexFCBv1Fu6ayMeta } from "/opt/build/repo/pages/activity/[parent]/[slug]/index.vue?macro=true";
import { default as indexCvqKFUPAkdMeta } from "/opt/build/repo/pages/activity/[parent]/index.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as newsletter4iem4JeVdNMeta } from "/opt/build/repo/pages/newsletter.vue?macro=true";
import { default as component_45stubMhO4CFyY6uMeta } from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@types+node@20.17.6_eslint@8.57.1_rollup@4.24.4_sass@1.80.6_stylelint@14.16.1_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubMhO4CFyY6u } from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@types+node@20.17.6_eslint@8.57.1_rollup@4.24.4_sass@1.80.6_stylelint@14.16.1_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "activity-parent-slug___it",
    path: "/:parent()/:slug()",
    component: () => import("/opt/build/repo/pages/activity/[parent]/[slug]/index.vue")
  },
  {
    name: "activity-parent___it",
    path: "/:parent()",
    component: () => import("/opt/build/repo/pages/activity/[parent]/index.vue")
  },
  {
    name: "index___it",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "newsletter___it",
    path: "/newsletter",
    component: () => import("/opt/build/repo/pages/newsletter.vue")
  },
  {
    name: component_45stubMhO4CFyY6uMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubMhO4CFyY6u
  }
]