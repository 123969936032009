import validate from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@types+node@20.17.6_eslint@8.57.1_rollup@4.24.4_sass@1.80.6_stylelint@14.16.1_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/pages/runtime/validate.js";
import netlify_45redirects_45global from "/opt/build/repo/middleware/netlify-redirects.global.ts";
import remove_45trailing_45slash_45global from "/opt/build/repo/middleware/remove-trailing-slash.global.ts";
import manifest_45route_45rule from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@types+node@20.17.6_eslint@8.57.1_rollup@4.24.4_sass@1.80.6_stylelint@14.16.1_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  netlify_45redirects_45global,
  remove_45trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}